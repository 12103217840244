
import { Component, Prop, Mixins } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
interface passwordData {
  password: string;
  password_confirm: string;
}

@Component
export default class ResetPasswordForm extends Mixins(FormValidator) {
  protected formData: passwordData = {
    password: "",
    password_confirm: ""
  };

  private credentials_found = true;
  private user_email = "";
  private confirm = false;

  private mounted() {
    if (
      typeof this.$route.query.token === "undefined" ||
      this.$route.query.token.toString() === ""
    ) {
      this.$router.push({ name: "home" });
    }
  }
  private resetPassword(): boolean {
    this.$axios
      .post("personas/recuperar/contrasena/response", {
        password: this.formData.password,
        password_confirmacion: this.formData.password,
        token: this.$route.query.token
      })
      .then(response => {
        this.$router.push({ name: "login" });
        this.$q.notify({
          message: this.$t("notification.successfull_password_reset") as string,
          color: "accent",
          position: "top",
          timeout: 7500
        });
      })
      .catch(error => {
        this.$router.push({ name: "home" });
        this.$q.notify({
          message: this.$t(
            "notification." + error.response.data.message
          ) as string,
          color: "accent",
          position: "top",
          timeout: 7500
        });
      });
    return true;
  }
}
