
import { Component, Prop, Vue } from "vue-property-decorator";
import FormCard from "@/components/FormCard.vue";
import LogoSingle from "@/components/global/LogoSingle.vue";
import ResetPasswordForm from "@/components/forms/ResetPasswordForm.vue";

@Component({
  components: {
    FormCard,
    LogoSingle,
    ResetPasswordForm
  }
})
export default class ResetPassword extends Vue {}
